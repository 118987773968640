@import '../../assets/scss/variables';

.MediaExternalCopies {
  .media-external-copy-item {
    padding-top: $ws-space-sm;
    padding-bottom: $ws-space-sm;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;

    & > span {
      flex: 1;
    }

    .StyledButton {
      .MuiButton-root {
        padding: 0px 15px;
        font-size: 14px;
      }
    }

    .media-release-icon {
      color: #a5a5a5;
      font-size: 21px;
      vertical-align: middle;
      margin-right: $ws-space-sm;
      cursor: pointer;
    }

    &:last-child {
      padding-top: $ws-space-sm;
      padding-bottom: $ws-space-sm;
      border-bottom: none;
    }

    .external-copies-button {
      svg {
        color: #E1E1E1;
      }
    }
  }
}