@import '../../assets/scss/_variables';

.RegenerateMediaData {
  width: 50vw;
  display: flex;
  flex-direction: column;

  .regenerate-media-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .media-item-image {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 500px;
      max-height: 500px;
      border-radius: 13px;
    }
  }
  .form-group {
    margin-top: $ws-space-lgm;
    width: 100%;

    .form-group-label {
      font-size: 14px;
      margin-bottom: $ws-space-sm;
      color: #383838;
    }
    .form-group-container {
      padding: 10px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 13px;

      .form-group-container {
        display: flex;
      }
      .form-media-regenerate-button {
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-left: 1px solid #e5e5e5;
      }
    }
    .form-group-footer {
      display: flex;

      .form-group-error {
        color: #FF0000;
        font-size: 14px;
        margin-top: 10px;
        flex: 1;
      }
      .form-input-length {
        font-size: 12px;
        color: $ws-color-gray;
        text-align: right;
        padding: 12px 0 0 12px;
      }
    }
  }
  .MuiFilledInput-input, .MuiFilledInput-root {
    border-radius: 13px;

    &::before, &::after {
      display: none !important;
    }
  }
  .MuiFormControlLabel-root {
    padding: $ws-space-sm 0;
    border-top: 1px solid #C4C4C4;

    &:last-child {
      border-bottom: 1px solid #C4C4C4;
    }
  }
  .MuiTextField-root {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 13px;
    }
  }
  .regenerate-button {
    margin-top: $ws-space-md;
  }
}
