@import '../../assets/scss/variables';

.MediaDatePicker {
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      padding: 0;
      
      input {
        padding: 0;
      }
    }
  }
}