@import '../../assets/scss/variables';

.MediaKeywords {
  display: flex;
  flex-direction: column;

  .info-text {
    font-size: 14px;
    margin: 5px;
    color: grey;
    text-align: center;

    span {
      display: block;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .media-keywords-action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $ws-space-sm;

    .keywords-copy-icon {
      cursor: pointer;
      margin-right: $ws-space-sm;
      position: relative;

      &.copied {
        span {
          opacity: 1;
          visibility: visible;
          top: -40px;
        }
      }
      span {
        position: absolute;
        top: -30px;
        left: 10px;
        transform: translateX(-50%);
        background-color: white;
        color: $ws-color-blue;
        padding: 10px;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 13px;
        pointer-events: none;
        opacity: 0;
        visibility: 0;
        transition: 300ms all;
      }
      svg {
        color: #a5a5a5;
        font-size: 17px;
      }
      &:hover svg {
        color: #353535;
      }
    }
    .keywords-delete-icon {
      cursor: pointer;

      svg {
        color: #a5a5a5;
        font-size: 21px;
      }
      &:hover svg {
        color: #353535;
      }
    }
  }
  .media-keywords-keywords-container {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    padding: $ws-space-md;
    padding-bottom: $ws-space-sm;
    min-height: 58px;

    & > .media-keywords-draggable-element {
      display: contents;
    }
    .LoadingProgress {
      transform: scale(0.5);
    }
    .MuiChip-outlined {
      margin-right: $ws-space-sm;
      margin-bottom: $ws-space-sm;
      cursor: pointer;

      &.ghost {
        border: none;
        background-color: $ws-color-lightest-gray;
      }

      &.dragged {
        border: none;
        background-color: $ws-color-blue;
      }
    }
    .keywords-input {
      top: -3px;
      display: inline-block;
      border: 1px solid #bdbdbd;
      border-radius: 90px;
      height: 32px;

      input {
        height: 30px;
        font-size: 13px;
        padding: 0 10px;
        box-sizing: border-box;
      }
      &:after, &:before {
        display: none;
      }
    }
  }
  .chip-container {
    position: relative;
    display: inline-block;

    .keyword-tooltip-container {
      width: 253px;
      position: absolute;
      display: none;
      bottom: 100%;
      padding-bottom: $ws-space-sm;
      z-index: 1;

      .keyword-tooltip {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: $ws-color-white;
        box-shadow: $ws-shadow-main;
        padding: $ws-space-md;
        border-radius: $ws-radius-main;

        span {
          font-size: 12px;
          line-height: 1.2;
        }

        > div {
          width: 100%;
          display: flex;
          gap: $ws-space-sm;
          align-items: center;

          span {
            color: $ws-color-gray;
            padding-top: $ws-space-sm;
          }
        }

        &-ignore {
          color: $ws-color-blue;
          cursor: pointer;
        }
      }
    }

    &.selected > div {
      border-color: #4776E6;
    }

    &:hover {
      .keyword-tooltip-container {
        display: flex;
      }
    }
  }
  .media-keywords-status {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    margin-top: $ws-space-sm;

    p {
      span {
        color: #C4C4C4;
      }
    }
  }
  .media-keywords-generate {
    display: flex;
    align-items: center;
    margin: $ws-space-lgm 0;

    .media-keywords-generate-input {
      flex: 1;
      margin-right: $ws-space-md;
      border: 1px solid rgba(0,0,0,.1);
      padding: $ws-space-sm $ws-space-md $ws-space-sm $ws-space-md;
      font-size: 14px;
      border-radius: 13px;

      &:before, &:after {
        display: none;
      }
    }
    .StyledButton {
      .MuiButton-containedPrimary {
        background-color: #58C321;
        padding: 10px 20px;
      }
    }
  }
  .media-keywords-generate-legends {
    display: flex;
    font-size: 14px;

    .media-keywords-legend-title {
      flex: 1;
      margin-bottom: $ws-space-sm;
    }
    .media-keywords-legend-tags {
      display: flex;

      span:first-child {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 6px solid;
        margin-right: $ws-space-sm;
      }
      span {
        vertical-align: middle;
      }
      & > div {
        margin-left: $ws-space-lgm;
      }
      & > div.ai-tags {
        color: #4776E6;
      }
      & > div.generated-tags {
        color: #58C321;
      }
    }
  }
}
