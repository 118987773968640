// Font Families
$ws-font-regular: Proxima-Regular, sans-serif;
$ws-font-semi-bold: Proxima-SemiBold, sans-serif;
$ws-font-bold: Proxima-Bold, sans-serif;
$ws-font-extra-bold: Proxima-Extra-Bold, sans-serif;

// Default spaces for margins and paddings
$ws-space-sm: 8px;
$ws-space-line: 12px;
$ws-space-md: 16px;
$ws-space-xmd: 26px;
$ws-space-lgm: 32px;
$ws-space-lg: 36px;
$ws-space-lgb: 56px;
$ws-space-big: 64px;
$ws-space-extra-lg: 256px;

// Colors
$ws-color-white: #FFFFFF;
$ws-color-blue: #4776E6;
$ws-color-gray: #2D2D2D;
$ws-color-dark-gray: #383838;
$ws-color-light-gray: #C4C4C4;
$ws-color-lightest-gray: #EEEEEE;
$ws-color-gold: #f4aa43;
$ws-color-orange: #FF7C04;
$ws-color-red: #FF0000;
$ws-color-green: #58C321;
$ws-color-blue-hover: #799AE9;
$ws-color-backdrop: rgba(0, 0, 0, 0.6);

//Shadows
$ws-shadow-main: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);

//Radius
$ws-radius-main: 13px;
