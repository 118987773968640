@import '../../assets/scss/variables';

.MediaCameraInfo {
  display: flex;

  .media-camera-info {
    display: flex;
    align-items: center;
    border-right: 1px solid black;
    padding-right: $ws-space-md;
    margin-right: $ws-space-md;
    font-weight: 600;

    img {
      margin-right: 10px;
    }
  }
  .media-size-info {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}
