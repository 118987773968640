@import '../../assets/scss/variables';

.MediaItemSlider {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .media-item-slider-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $ws-color-backdrop;
  }
  .media-item-slider-close {
    position: absolute;
    top: $ws-space-md;
    left: $ws-space-lgm;
    color: white;
    cursor: pointer;
    z-index: 1000;
  }
  .media-item-slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;

    .media-item-image > div > div:last-child {
      transform: translateX(-100%);
      margin-left: 0 !important;
      border: none !important;
    }

    &.vertical-image {
      .media-item-image > div > img {
        width: initial !important;
        max-height: 700px;
      }
    }
    &.horizontal-image {
      .media-item-image > div > img {
        max-width: 600px;
      }
    }
    &.loaded {
      .media-item-preview {
        background: transparent;
      }
    }
    .media-item-slider-arrow-left,
    .media-item-slider-arrow-right {
      color: white;
      margin: 0 $ws-space-sm;
      cursor: pointer;

      .MuiSvgIcon-root {
        font-size: 34px;
      }
      &.disable {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    .media-item-preview-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .media-item-preview {
      border-radius: 13px;
      //overflow: hidden;
      background-color: #f2f2f2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-width: 100px;
      min-height: 100px;

      .media-item-slider-open {
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        background-color: rgba(0,0,0,0.2);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 18px;
        }
      }
      .media-item-image {
        display: flex;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .media-item-no-image {
        color: rgba(165, 165, 165, 0.48);
        min-width: 200px;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiSvgIcon-root {
          font-size: 100px;
        }
      }
    }
    .media-item-slider-status {
      position: absolute;
      color: white;
      bottom: -30px;

      span {
        margin: 0 5px;
      }
    }
  }
}
