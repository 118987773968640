@import '../../../../assets/scss/variables';

.Ranker-element {
  width: 100vw;
  height: 100%;
  display: flex;
  padding: $ws-space-lgm;

  .info-text {
    font-size: 14px;
    margin: 5px;
    color: grey;
    text-align: center;
    margin-top: 60px;
    width: 100%;

    span {
      display: block;
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  .ranker-image-container {
    width: 330px;
    margin-right: $ws-space-lgm;

    .ranker-image {
      width: 100%;
      min-height: 330px;
      background-color: #F2F2F2;
      border-radius: 13px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .MuiSvgIcon-root {
        font-size: 160px;
        color: rgba(165, 165, 165, 0.48);
      }
    }
  }
  .ranker-image-details {
    flex: 1;

    .ranker-image-title {
      font-family: $ws-font-extra-bold;
      font-size: 20px;
      margin-bottom: $ws-space-md;
    }
    .ranker-image-category {
      font-size: 20px;
      margin-bottom: $ws-space-md;
    }
    .ranker-image-info {
      font-size: 14px;
      margin-bottom: $ws-space-md;
    }
    .ranker-image-keywords {
      width: 100%;
      padding: $ws-space-md;
      padding-bottom: $ws-space-sm;
      padding-right: $ws-space-sm;
      border: 1px solid rgba(0,0,0,.1);
      border-radius: 13px;
      margin-bottom: $ws-space-md;
      font-family: $ws-font-bold;

      & > div {
        font-weight: 700;
        display: inline-block;
        padding: $ws-space-sm;
        border-radius: 30px;
        border: 1px solid rgba(0,0,0,.2);
        margin-right: $ws-space-sm;
        margin-bottom: $ws-space-sm;
      }
    }
    .ranker-image-buttons {
      display: flex;

      .ranker-image-rank {
        font-family: $ws-font-bold;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0,0,0,.3);
        border-radius: 13px;
      }
      .StyledButton {
        margin-left: $ws-space-md;
      }
    }
    .ranker-image-reasons {
      display: flex;
      margin-top: $ws-space-big;

      & > div {
        flex: 1;
        border: 1px solid rgba(0,0,0,0.1);
        padding: $ws-space-lgm;
        border-radius: 13px;
        
        &:first-child {
          margin-right: $ws-space-lgm;
        }
      }
      .MuiFormControlLabel-root {
        display: block;
      }
    }
  }
}