@import '../../assets/scss/_variables';

.NavLink {
    display: inline-block;

    a {
        color: grey;
        font-family: $ws-font-bold;
        text-decoration: none;

        &.active {
            color: #4050b5;
        }
    }
}