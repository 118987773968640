@import '../../../assets/scss/variables';

.Home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .Home-element {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 42px;
            font-family: $ws-font-extra-bold;
        }
        img {
            width: 220px;
        }
        .home-element-button {
            width: 290px;
            margin-top: 16px;
        }
        .StyledButton {
            margin-bottom: 20px;
        }
    }

    .NoAccess-element {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 30px;
            font-family: $ws-font-extra-bold;
            margin-bottom: $ws-space-lgb;
            text-align: center;
            color: $ws-color-gray;
        }
        img {
            width: 220px;
        }
        .home-element-button {
            width: 290px;
            margin-top: 16px;
        }
    }
}