.LoginHeader {
  z-index: 2;

  .MuiAppBar-colorDefault {
    background-color: white;
  }
  .header-base-component {
    display: block;
    width: 100%;
  }
  .MuiToolbar-regular {
    height: 68px;
  }
  .MuiTouchRipple-root {
    opacity: 0.3;
  }
  .app-header-logo {
    flex: 1;
  }
}
