@import '../../assets/scss/variables';

.MediaAgencies {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 16px;
  grid-row-gap: 8px;

  .media-agency-block {
    max-width: 241px;
    height: 42px;
    flex: 1 1 240px;
    display: flex;
    align-items: center;
    border: 1px solid #E1E1E1;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    padding: 8px;

    .media-agency-block-title {
      display: flex;
      flex: 1;
    }
    .media-agency-block-reject-icon {
      display: flex;
      cursor: pointer;

      div {
        display: flex;
      }

      .agency-reject-icon {
        svg {
          color: #E1E1E1;
        }
      }
    }

    &.disabled {
      background: #F2F2F2;
      border: 1px solid #F2F2F2;
      cursor: not-allowed;
      color: $ws-color-light-gray;
    }
  }
  & > .MuiPaper-root {
    margin-bottom: 8px;
    border-radius: 13px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    
    &::before {
      display: none;
    }
  }
  .info-text {
    font-size: 14px;
    margin: 5px;
    color: grey;
    text-align: center;

    span {
      display: block;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .LoadingProgress {
    transform: scale(0.5);
  }
  .MuiAccordionSummary-root {
    border: 1px solid #E1E1E1;
    border-radius: 13px;
    transition: 300ms all;

    &.Mui-expanded {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }
  .MuiAccordionSummary-root.rejected {
    background: #F2F2F2;
    border-radius: 13px;
  }
  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }
  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
  .media-agencies-title {
    font-family: $ws-font-bold;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .media-agencies-title-icon {
    margin-right: $ws-space-md;
  }
  .MuiAccordionDetails-root {
    padding: 8px 5px 16px 30px;
  }
  .MuiFormControlLabel-root {
    border-bottom: 1px solid #E1E1E1;
    padding: $ws-space-md 0;

    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
  .custom-reason-button {
    cursor: pointer;
    margin-top: $ws-space-md;
    margin-bottom: $ws-space-md;

    .MuiSvgIcon-root {
      vertical-align: middle;
      margin-left: $ws-space-sm;
    }
  }
  .custom-reason-textarea {
    padding-right: 20px;
    height: 0;
    overflow: hidden;
    transition: 300ms all;

    &.show {
      height: 55px;
    }
    .MuiTextField-root {
      width: 100%;
    }
    .MuiInputBase-root {
      border-radius: 13px;

      .MuiOutlinedInput-input {
        font-size: 14px;
      }
    }
  }
  .agency-rejected-message {
    color: $ws-color-red;
    font-family: $ws-font-bold;
    font-size: 14px;
    border-left: 1px solid rgba(0,0,0,.2);
    height: 16px;
    margin-top: 5px;
    padding-left: $ws-space-md;
    margin-left: $ws-space-md;
    display: flex;
    align-items: center;
  }
  .media-agency-button {
    margin-top: $ws-space-lgm;
    text-align: center;
  }
}