@import '_reset';
@import '_typography';
@import '_variables';

body,
html {
  font-family: $ws-font-regular, sans-serif;
}
strong {
  font-family: $ws-font-bold, sans-serif;
}
