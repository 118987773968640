@import '../../assets/scss/_variables';

.MediaItem {
  display: inline-block;
  height: 252px;
  background-color: #F2F2F2;
  border-radius: 13px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;
  transition: 100ms all;

  &.selected {
    box-shadow: 0 0 10px rgba(0,0,0,.2);

    .media-item-select-handler {
      background-color: rgba(0,0,0,.1);
      z-index: -1;
    }
  }
  &.cursor {
    border: 4px solid #000000;
  }
  &.submitted {
    outline: 1px solid #58C321;
  }
  .media-type-label {
    position: absolute;
    left: 8px;
    bottom: 8px;
    pointer-events: none;
    font-size: 12px;
    text-transform: capitalize;
    color: #FF7C04;
    background-color: #2D2D2D;
    padding: 6px 8px;
    border-radius: 13px;

    &.commercial {
      color: #58C321;
    }
  }
  .media-item-select-handler {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 100ms all;
  }
  .media-item-zoom {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .media-item-selected-status {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: white;
    height: 24px;
    border-radius: 100%;

    &.error {
      color: #FF0000;
    }
  }
  .media-item-id {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 12px;
    background-color: #2D2D2D;
    padding: 6px 8px;
    border-radius: 13px;
    color: white;
    width: auto;
    overflow: hidden;
    transition: 300ms all;
    span {
      margin-left: 4px;
    }
    &:hover {
      padding-left: 50px;
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
    &::after {
      content: 'Copy';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $ws-color-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      transition: 200ms all;
    }

    &.copied::after {
      content: 'Copied!';
    }
  }
  .media-item-warning {
    position: absolute;
    bottom: 36px;
    right: 8px;
    font-size: 12px;
    background-color: #2D2D2D;
    padding: 8px 8px;
    border-radius: 8px;
    color: #FF7C04;
    width: auto;
    height: 30;
    overflow: hidden;
    transition: 300ms all;
  }
  .media-item-warning {
    position: absolute;
    bottom: 40px;
    right: 8px;
    background-color: #2D2D2D;
    padding: 8px 8px;
    border-radius: 8px;
    color: #FF7C04;
    width: auto;
    overflow: hidden;
    transition: 300ms all;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    svg {
      font-size: 15px;
    }
  }
  .media-item-image {
    width: 100%;
    height: 100%;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .media-item-no-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    .MuiSvgIcon-root {
      font-size: 50px;
      opacity: 0.3;
    }
  }
  .media-item-deselected-icon {
    opacity: 0.5;
  }
  .media-item-selected-icon {
    color: #3f51b5;
  }
  .LoadingProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-100%) scale(0.5);
  }
}