@import '../../../assets/scss/variables';

.BatchContainer {
  height: calc(100vh - 68px);
  display: flex;

  & > .batch-media-container, & > .batch-media-form {
    height: 100%;
    display: flex;
  }
  & > .batch-media-container {
    flex: 1;
  }
  & > .batch-media-form {
    width: 615px;
  }
}