@import '../../assets/scss/variables';

.Dashboard {
  display: flex;
  background-color: #f2f2f2;
  height: 100%;
  padding: $ws-space-md $ws-space-lgm;
  align-items: flex-start;

  & > div {
    background-color: white;
    border-radius: 13px;
  } 
  .dashboard-chart-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .dashboard-chart-header {
      height: 100px;
      padding: $ws-space-lgm $ws-space-lgm 0 $ws-space-lgm;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);

      .dashboard-chart-title {
        font-family: $ws-font-extra-bold;
        font-size: 20px;
      }
      .dashboard-chart-tabs {
        display: flex;
        justify-content: flex-end;

        .MuiTab-root {
          text-transform: none;
          min-width: 100px;
        }
      }
    }
    .dashboard-chart {
      padding: $ws-space-lgm;

      .charts-message {
        text-align: center;
        font-size: 14px;
        position: relative;
        top: 20px;
        z-index: 1;
        color: $ws-color-gray;
      }
      .dashboard-chart-stats {
        margin-bottom: $ws-space-lgm;

        & > div {
          display: inline-block;
          border: 1px solid #f2f2f2;
          border-radius: 13px;
          padding: $ws-space-sm;
          margin-right: $ws-space-md;
        }
      }
    }
  }
  .dashboard-general-stats {
    width: 400px;
    margin-left: $ws-space-md;

    .dashboard-stats-header {
      height: 100px;
      padding: $ws-space-lgm $ws-space-lgm 0 $ws-space-lgm;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);

      .dashboard-chart-title {
        font-family: $ws-font-extra-bold;
        font-size: 20px;
      }
    }
    .dashboard-stats-content {
      padding: $ws-space-lgm;

      & > div {
        display: flex;
        justify-content: space-between;
        padding: $ws-space-md 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
