.LoadingProgress {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fixed {
    display: contents;
  }
}