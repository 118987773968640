@import '../../../assets/scss/variables';

.BatchMediaItems {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .info-text {
    text-align: center;
    margin-top: 20px;
    color: grey;
    font-size: 14px;

    span {
      display: block;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .no-media-block {
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }
  .batch-media-items-manager {
    padding-bottom: 16px;

    & > div {
      display: flex;

      p {
        margin-right: $ws-space-md;

        strong, span {
          margin-left: 3px;
        }
      }
    }
  }
  .batch-media-items {
    overflow: auto;
    padding: $ws-space-md 5px;
    padding-top: 0;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  }
  .batch-media-select-link {
    cursor: pointer;
  }
}
