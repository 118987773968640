@import '../../assets/scss/_variables';

.UndoSnackbar {
    width: 510px;
    height: 63px;
    background-color: $ws-color-dark-gray;
    position: absolute;
    left: 30px;
    bottom: 30px;
    border-radius: 13px;
    display: flex;
    color: white;
    align-items: center;
    padding: 16px;

    .snackbar-icon {
        margin-right: 8px;
    }
    .snackbar-title {
        flex: 1;
        margin-top: -1px;
    }
    .snackbar-loader {
        transform: scale(0.7);
        margin-right: 32px;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 24px;
            background-color: white;
            position: absolute;
            top: 8px;
            right: -27px;
        }
        .snackbar-loader-seconds {
            position: absolute;
            top: 12px;
            left: 15px;
            color: white;
        }
    }
    .snackbar-undo-button {
        cursor: pointer;
    }
}