@import '../../assets/scss/variables';

.MediaReleases {
  .media-release-item {
    padding-top: $ws-space-sm;
    padding-bottom: $ws-space-md;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .media-release-icon {
      color: #a5a5a5;
      font-size: 21px;
      vertical-align: middle;
      margin-right: $ws-space-sm;
      cursor: pointer;
    }

    &:first-child {
      padding-top: $ws-space-sm !important;
    }

    &:last-child {
      padding-top: $ws-space-md;
      padding-bottom: $ws-space-sm;
      border-bottom: none;
    }
  }
}
