.Header {
  z-index: 3;

  .MuiPaper-root {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  }
  .MuiAppBar-colorDefault {
    background-color: white;
  }
  .header-base-component {
    display: block;
    width: 100%;
  }
  .MuiToolbar-regular {
    height: 68px;
  }
  .MuiTouchRipple-root {
    opacity: 0.3;
  }
  .app-header-logo {
    flex: 1;
  }
  .app-header-account {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .app-header-links {
    flex: 2;
    text-align: center;

    .NavLink {
      margin-right: 64px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
