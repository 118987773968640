@import '../../assets/scss/_variables';

.RejectReason {
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;

  .info-text {
    font-size: 14px;
    margin: 5px;
    color: grey;
    text-align: center;
    margin-top: 40px;

    span {
      display: block;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .cursor {
    background-color: #dadada;
  }
  .reason-title {
    font-size: 24px;
    font-family: $ws-font-extra-bold;
    margin-bottom: $ws-space-md;
  }
  .reason-list {
    flex: 1;
    overflow: auto;
    margin: $ws-space-lgm 0;
  }
  .reason-action-buttons {
    display: flex;
    justify-content: flex-end;

    .StyledButton {
      margin-left: $ws-space-md;
    }
  }
  .MuiFilledInput-input, .MuiFilledInput-root {
    border-radius: 13px;

    &::before, &::after {
      display: none !important;
    }
  }
  .MuiFormControlLabel-root {
    padding: $ws-space-sm 0;
    border-top: 1px solid #C4C4C4;

    &:last-child {
      border-bottom: 1px solid #C4C4C4;
    }
  }
  .MuiTextField-root {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 13px;
    }
  }
  .StyledButton {
    margin-top: $ws-space-md;
  }
}
