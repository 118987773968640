@import '../../assets/scss/_variables';

.FlagReason {
  width: 500px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $ws-space-md;

  .flag-reason-title {
    font-size: 24px;
    font-family: $ws-font-extra-bold;
    margin-bottom: $ws-space-md;
  }
  .MuiTextField-root {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 13px;
    }
  }
  .StyledButton {
    margin-top: $ws-space-md;
  }
}
