@import '../../assets/scss/variables';

.MediaAdditionalInfo {
  .media-info-row {
    display: flex;
    font-size: 14px;

    .media-info-label {
      margin-right: 5px;
      color: $ws-color-blue;
      line-height: 20px;
    }
    .media-info-value {
      flex: 1;
      font-family: $ws-font-regular;
      line-height: 20px;
    }
    &:not(:first-child) {
      margin-top: $ws-space-md;
    }
    .keyword-item {
      border: 1px solid $ws-color-light-gray;
      padding: 4px 8px;
      border-radius: 13px;
      margin-right: 2px;
      display: inline-block;
      margin-bottom: 5px;
    }
  }
}