@import '../../assets/scss/_variables';

.ProgressStatus {
  position: relative;

  .progress-status-numbers {
    width: 100%;
    display: flex;
    padding-bottom: $ws-space-sm;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-family: $ws-font-extra-bold;
      align-items: center;

      &:first-child {
        align-items: flex-start;
      }
      &:last-child {
        align-items: flex-end;
      }
      &::after {
        content: '';
        height: 10px;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.6);
        position: relative;
        top: 3px;
      }
    }
  }
  .progress-status-completed {
    position: absolute;
    top: 5px;
    left: 50%;
    display: none;
    transform: translateX(-50%);

    p {
      display: inline-block;
      margin-right: 10px;
      font-size: 14px;
      color: rgba(0,0,0,.7);
    }
  }
  .MuiLinearProgress-root {
    background-color: #E1E1E1;
    border-radius: 20px;
  }
  .MuiLinearProgress-bar {
    background-color: #FF7C04;
  }
  &.completed {
    .progress-status-numbers > div {
      color: transparent;

      &::after {
        background-color: transparent;
      }
    }
    .progress-status-completed {
      display: block;
      display: flex;

      span {
        animation: pulse 1.5s infinite;
      }
    }
    .MuiLinearProgress-bar {
      background-color: #ff3f3e;
    }
  }
}

@keyframes pulse {
	10% {transform: scale(1.3)}
}