@import '../../assets/scss/_variables';

.MuiBackdrop-root {
  background-color: $ws-color-backdrop !important;
}
.MuiDialog-container {
  .MuiPaper-root {
    border-radius: 13px;
    max-width: 100vw;
    max-height: 100vh;
  }
  .MuiDialogContent-root {
    padding: $ws-space-lgm !important;
  }
}
