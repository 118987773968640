@import '../../assets/scss/variables';

.Login {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Main-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $ws-space-lgm;

      h3 {
        font-family: $ws-font-extra-bold;
        font-weight: 800;
        margin-bottom: $ws-space-lgm;
      }
      .login-error-message {
        text-align: center;
        color: #FB0404;
        margin-bottom: $ws-space-lg;

        p {
          font-size: 12px;
          margin-top: $ws-space-sm;
        }
      }
      .login-form-elements {
        display: flex;
        flex-direction: column;

        .MuiTextField-root {
          width: 290px;
          margin-bottom: $ws-space-lgm;

          .MuiOutlinedInput-root {
            border-radius: 13px;
          }
        }
      }
      .StyledButton {
        width: 100%;

        .MuiButtonBase-root {
          width: 100%;
          padding: 8px;
        }
      }
  }
}