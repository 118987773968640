@import '../../../../../assets/scss/variables';

.HeaderProfile {
  display: flex;
  height: $ws-space-md;

  .header-profile-counts {
    border-right: 1px solid black;
    padding-right: $ws-space-md;
    margin-right: $ws-space-md;
  }
  .header-profile-name {
    border-right: 1px solid black;
    padding-right: $ws-space-md;
    margin-right: $ws-space-sm;
  }
  .header-profile-menu {
    .MuiButton-label, .MuiListItem-root {
      text-transform: capitalize;
    }
    .MuiList-root {
      a {
        color: black;
        text-decoration: none;
      }
    }
    .MuiButton-root {
      font-family: $ws-font-bold;
      letter-spacing: 0;
      transform: translateY(-12px);
      text-transform: capitalize;
      color: black;
      font-size: 16px;

      &:hover {
        background-color: transparent;
      }
    }
  }
  .header-profile-dropdown {
      transform: translateX(-20px) !important;
      border-radius: 13px;

      .header-profile-dropdown-item {
        font-family: $ws-font-bold;
        padding: 12px 16px;
        text-transform: capitalize;
      }
  }
}
