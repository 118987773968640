@import '../../assets/scss/variables';

.MediaTypeSwitch {
  margin-top: -7px;
  margin-bottom: -8px;
  
  span {
    font-size: 14px;
    font-family: $ws-font-semi-bold;

    &.disabled {
      color: #C4C4C4;
    }
  }
}