@import '../../assets/scss/_variables';

.StyledButton {
    display: inline-block;

    .MuiButtonBase-root {
        text-transform: inherit;
        border-radius: 13px;
        font-size: 16px;
        font-family: $ws-font-semi-bold;
    }
    .MuiButton-containedPrimary {
        background-color: $ws-color-blue;
    }
    .MuiButton-outlinedPrimary {
        color: $ws-color-blue;
    }
}