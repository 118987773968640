@import '../../../assets/scss/variables';

.BatchMediaForm {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  padding: $ws-space-md;

  .batch-media-no-selected {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 13px;
    padding: $ws-space-xmd;
    overflow: auto;

    .no-media-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 70px;
        margin-bottom: $ws-space-md;
        color: grey;
      }
    }
  }

  .batch-media-form-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 13px;
    padding: $ws-space-xmd;
    overflow: auto;
    position: relative;

    .batch-media-form-actions-container {
      position: sticky;
      top: -26px;
      background-color: white;
      z-index: 1;
      padding: 16px 0;
    }

    .description-suggestions-container {
      .MuiChip-outlined {
        margin-right: $ws-space-sm;
        margin-bottom: $ws-space-sm;
        cursor: pointer;
  
        &.ghost {
          border: none;
          background-color: $ws-color-lightest-gray;
        }
  
        &.dragged {
          border: none;
          background-color: $ws-color-lightest-gray;
        }
      }
    }
    .media-update-status {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: grey;
      border-radius: 13px;
      float: right;
      position: sticky;
      top: -12px;
      right: 0;
      z-index: 2;

      .status-container {
        span {
          display: none;
          align-items: center;
          gap: 5px;
          font-size: 12px;
          font-family: $ws-font-bold;

          svg {
            font-size: 15px;
          }
        }
        &.no-change {
          .no-change {
            display: inline-flex;
          }
        }
        &.saving {
          .saving {
            display: inline-flex;
          }
        }
        &.saved {
          .saved {
            display: inline-flex;
            color: $ws-color-green;
          }
        }
        &.failed {
          .failed {
            display: inline-flex;
            color: $ws-color-red;
          }
        }
      }
    }
    .batch-media-license-type {
      flex: 1;
      display: flex;
      text-transform: capitalize;
      font-family: $ws-font-semi-bold;
      margin-bottom: 16px;
      font-size: 14px;
      gap: 5px;
      align-items: center;

      span {
        padding: 0 6px;
      }
    }
    .batch-media-action-buttons {
      display: flex;

      .StyledButton {
        margin-right: $ws-space-md;

        .button-orange {
          color: #FF7C04;
          border-color: #FF7C04;
        }
      }
    }
    .capitalized-paragraph {
      text-transform: capitalize;
      font-family: $ws-font-semi-bold;
    }
    .form-group-divided {
      display: flex;

      .form-group {
        flex: 1;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
    .form-group {
      margin-top: $ws-space-lgm;

      &.media-title {
        margin-top: $ws-space-sm;
      }

      &.form-group-readonly {
        background-color: $ws-color-lightest-gray;
        padding: 16px;
        border-radius: 13px;
        border: 1px solid $ws-color-orange;

        .form-group-label {
          font-family: $ws-font-bold;
        }
        .form-group-container {
          border: none;
          padding: 0;
        }
      }
      .form-group-label {
        font-size: 14px;
        margin-bottom: $ws-space-sm;
        color: #383838;
      }
      .form-group-container {
        padding: 10px 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 13px;

        .form-group-container {
          display: flex;
        }
        .form-media-regenerate-button {
          display: flex;
          width: 100%;
          justify-content: end;
        }
      }
      .form-group-footer {
        display: flex;

        .form-group-error {
          color: #FF0000;
          font-size: 14px;
          margin-top: 10px;
          flex: 1;
        }
        .form-input-length {
          font-size: 12px;
          color: $ws-color-gray;
          text-align: right;
          padding: 12px 0 0 12px;
        }
      }
    }
    .form-group-error {
      color: #FF0000;
      font-size: 14px;
      margin-top: 10px;
    }
    .batch-media-submit {
      text-align: center;
      margin-top: $ws-space-lgm;

      .MuiButton-root {
        width: 300px;
      }
    }
    .batch-media-ai {
      border-color: black;
      color: black;

      &[disabled] {
        opacity: 0.3;
      }
    }
    .curator-note-input, .curator-additional-info-input {
      width: 100%;
      
      div::before, div:hover::before, &::before, &:hover::before, &  {
        border: none;
      }
    }
  }
}
