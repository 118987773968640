@import '../../../assets/scss/variables';

.BatchMediaContainer {
  width: 100%;
  height: calc(100vh - 68px);
  overflow: auto;
  padding: $ws-space-md $ws-space-xmd;
  position: relative;
  display: flex;
  flex-direction: column;

  .batch-pagination {
    position: absolute;
  }
  .batch-media-action-buttons {
    display: flex;

    .StyledButton {
      margin-right: $ws-space-md;

      button {
        width: 53px;
        min-width: 53px;
        height: 42px;
      }
    }
    .batch-keywoarder-button {
      margin-bottom: $ws-space-md;
    }
    .MuiButton-containedSuccess {
      background-color: #58C321 !important;
    }
  }
  .batch-media-info-container {
    margin-top: $ws-space-md;
    margin-bottom: $ws-space-md;

    .batch-media-ai-generated {
      width: 25px;
      height: 25px;
      background-color: orange;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
        font-size: 20px;
      }
    }
    .premium-label {
      display: inline-block;
      padding: 5px 20px;
      background-color: $ws-color-gold;
      margin-left: $ws-space-md;
      border-radius: 13px;
      color: $ws-color-white;
      font-size: $ws-space-line;
    }
    & > div {
      display: flex;
      margin-bottom: $ws-space-sm;

      & > div {
        display: flex;
        align-items: center;
      }
      & > div:first-child {
        border-right: 1px solid black;
        padding-right: $ws-space-md;
        margin-right: $ws-space-md;
      }
      & > div:last-child {
        border-right: none;
      }
      strong {
        margin-right: $ws-space-sm;
      }
    }
  }
  .keyworder-info-container {
    font-size: 14px;

    & > div {
      display: flex;
      margin-bottom: $ws-space-sm;

      & > div {
        padding-right: $ws-space-sm;
        margin-right: $ws-space-sm;
      }
    }
  }
  .batch-media-files {
    flex: 1;
    overflow: auto;
  }
  .batch-media-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
