.Main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Main-container {
    flex: 1;
  }
  & > .LoadingProgress {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
  }
}
